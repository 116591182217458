import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { DataService } from "../data/data.service";
import {
  FirestoreService,
  SearchingService
} from "../firestore/firestore.service";
import { References } from "../firebase/firebase.service";
import { MessagingService } from "../messaging/messaging.service";
import { accessLimitations } from "src/app/constants";

@Injectable()
export class AuthService {
  constructor(
    private afAuth: AngularFireAuth,
    private httpClient: HttpClient,
    private firestoreService: FirestoreService,
    private router: Router,
    private ref: References,
    private dataService: DataService,
    private searchingService: SearchingService,
    private messagingService: MessagingService
  ) {}

  async createCustomToken(userID) {
    let token = "";
    await new Promise(resolve => {
      let header = new HttpHeaders();
      header.append("Content-Type", "application/x-www-form-urlencoded");
      this.httpClient
        .post(
          "https://us-central1-peprix-2a59f.cloudfunctions.net/createCustomToken",
          {
            user_id: userID
          },
          { headers: header }
        )
        .subscribe(
          data => {
            token = Object.values(data)[1];
            resolve(token);
            return token;
          },
          error => {
            console.error("Error", error);
          }
        );
    });
    return token;
  }

  async validate(workspaceName, email, password, progressRef, notifier) {
    let workspaceId, userId;
    await this.ref.workspaces
      .where("workspaceName", "==", workspaceName)
      .get()
      .then(querySnapshot => {
        let workspaceID = "";
        let docs = querySnapshot.docs;
        docs.forEach(doc => {
          workspaceID = doc.id;
        });
        return workspaceID;
      })
      .then(async workspaceID => {
        let userID = "";
        await new Promise(resolve => {
          this.ref.users
            .where("email", "==", email)
            .where("password", "==", password)
            .where("workspaceID", "==", workspaceID)
            .get()
            .then(querySnapshot => {
              if (querySnapshot.docs.length < 1) {
                progressRef.complete();
                document.getElementById("alert").style.display = "block";
                return;
              } else {
                let doc = querySnapshot.docs[0];
                let data = doc.data();
                if (!data.active) {
                  progressRef.complete();
                  notifier.notify(
                    "error",
                    "Your account has been deactivated by the admin"
                  );
                  return;
                }
                userID = doc.id;
                this.dataService.isAdmin = data.isAdmin;
                this.dataService.isCustomer = data.isCustomer;
              }
              resolve();
            })
            .catch(error => {
              console.error(error);
            });
        });
        workspaceId = workspaceID;
        userId = userID;
      })
      .catch(error => {
        console.error(error);
      });
    return { workspaceId, userId };
  }

  login(email, workspaceID, userID, progressRef, navigate) {
    new Promise(resolve => {
      this.dataService.membersSubscription = this.firestoreService
        .getWorkspaceMembers(workspaceID)
        .subscribe(items => {
          this.dataService.members = items;
          this.firestoreService.members = items;
          this.searchingService.members = items;
        });
      if (accessLimitations) {
        this.dataService.accountsSubscription = this.firestoreService
          .getAccount(workspaceID)
          .subscribe(accounts => {
            this.dataService.account = accounts[0];
          });
      }
      resolve({ workspaceID, userID });
    })
      .then(async ({ workspaceID, userID }) => {
        let token = await this.createCustomToken(userID);
        return { workspaceID, userID, token };
      })
      .then(({ workspaceID, userID, token }) => {
        this.doLogin(token)
          .then(result => {
            if (result) {
              // this.userService.updateUser(email).then(() => {
              progressRef.complete();
              this.dataService.data = true;
              this.dataService.email = email;
              this.dataService.userID = userID;
              this.dataService.workspaceID = workspaceID;
              if (localStorage.getItem("redirect")) {

                this.router.navigate([localStorage.getItem("redirect")]);
                // window.location.href = `${localStorage.getItem("redirect")}`;

              } else {
                this.router.navigate(navigate);
              }
              // });
            }
          })
          .then(() => {
            this.messagingService.requestPermission(userID);
          })
          .catch(function(error) {
            console.error(error);
          });
      })
      .catch(error => {
        console.error(error);
      });
  }

  async doLogin(token) {
    return await new Promise<any>((resolve, reject) => {
      this.ref.auth.signInWithCustomToken(token).then(
        res => {
          resolve(res);
        },
        err => reject(err)
      );
    });
  }

  doLogout() {
    return new Promise((resolve, reject) => {
      if (this.ref.auth.currentUser) {
        this.afAuth.auth.signOut();
        resolve();
      } else {
        reject();
      }
    });
  }

  authListener() {
    return this.afAuth.user;
  }
}
