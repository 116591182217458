export const regex = {
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  urlExp: /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi,
  boldExp: /\*(.*?)\*/gi,
  italicExp: /\b_(.*?)_\b(?!@)/gi,
  bulletedExp: /^\-\s(.*)/gm,
  emailExp: /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})/gi
};

export const fileTypes = {
  image: ["jpg", "jpeg", "png", "tiff", "tif", "gif","heic","svg","raw"],
  audio: ["wav", "amr", "mp3","m4a","flac","wma","aac"],
  video: ["mp4", "avi", "3gp", "mkv", "mov", "mpeg", "mpg", "flv","ogg","m4v","m4p","qt","swf","wmv","webm"],
  compress: ["zip", "7zip", "rar", "tar", "xz","gzip","ice","rte","pkg","taz","archiver","zix"],
  doc: ["rtf", "docx", "doc","odt", "pdf", "ods", "txt"],
  ppt: ["ppt", "pptx"],
  excel: ["xlsx", "xls"],
  technical: [
    "db",
    "sql",
    "sqlite",
    "mdb",
    "h",
    "m",
    "swift",
    "asp",
    "aspx",
    "axd",
    "asx",
    "asmx",
    "ashx",
    "css",
    "scss",
    "sass",
    "yaws",
    "html",
    "htm",
    "xhtml",
    "jhtml",
    "shtml",
    "java",
    "jsp",
    "jspx",
    "kt",
    "js",
    "jsx",
    "ts",
    "pl",
    "php",
    "php4",
    "php3",
    "phtml",
    "py",
    "rb",
    "rhtml",
    "xml",
    "otf",
    "ttf",
    "csv",
    "json",
    "apk",
    "psd",
    "ai",
    "eps",
    "heif",
    "webp",
    "ipa",
    "xd",
    "dart"
  ],
};

export const plans = {
  FP: {
    name: "Free Plan",
    price: 0,
    members: "2",
    projects: "2",
    storage: "2 GB",
    availableMembers: 2,
    availableProjects: 2,
    availableStorage: 2000000
  },
  ST1: {
    name: "Startup Package #1",
    price: 10,
    members: "5",
    projects: "5",
    storage: "5 GB",
    availableMembers: 5,
    availableProjects: 5,
    availableStorage: 5000000
  },
  ST2: {
    name: "Startup Package #2",
    price: 30,
    members: "10",
    projects: "10",
    storage: "25 GB",
    availableMembers: 10,
    availableProjects: 10,
    availableStorage: 25000000
  },
  PRO1: {
    name: "Pro Package #1",
    price: 80,
    members: "15",
    projects: "Unlimited",
    storage: "Unlimited",
    availableMembers: 15,
    availableProjects: 1000,
    availableStorage: 1000000000
  },
  PRO2: {
    name: "Pro Package #2",
    price: 150,
    members: "25",
    projects: "Unlimited",
    storage: "Unlimited",
    availableMembers: 25,
    availableProjects: 1000,
    availableStorage: 1000000000
  },
  PRO3: {
    name: "Pro Package #3",
    price: 250,
    members: "70",
    projects: "Unlimited",
    storage: "Unlimited",
    availableMembers: 70,
    availableProjects: 1000,
    availableStorage: 1000000000
  },
  EP: {
    name: "Enterprise Package",
    price: 999,
    members: "1000",
    projects: "Unlimited",
    storage: "Unlimited",
    availableMembers: 1000,
    availableProjects: 1000,
    availableStorage: 1000000000
  },
  TP: {
    name: "Trial Package",
    price: 0,
    members: "10",
    projects: "10",
    storage: "Unlimited",
    availableMembers: 10,
    availableProjects: 10,
    availableStorage: 1000000000
  }
};

export const countries = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "British Virgin Islands",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Cote D Ivoire",
  "Croatia",
  "Cruise Ship",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Polynesia",
  "French West Indies",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kuwait",
  "Kyrgyz Republic",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Namibia",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Norway",
  "Oman",
  "Pakistan",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Pierre and Miquelon",
  "Samoa",
  "San Marino",
  "Satellite",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "South Africa",
  "South Korea",
  "Spain",
  "Sri Lanka",
  "St Kitts and Nevis",
  "St Lucia",
  "St Vincent",
  "St. Lucia",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor LEste",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Venezuela",
  "Vietnam",
  "Virgin Islands (US)",
  "Yemen",
  "Zambia",
  "Zimbabwe"
];

export const notifierConfig = {
  behaviour: {
    autoHide: 3000
  },
  animations: {
    enabled: true,
    show: {
      preset: "fade",
      speed: 300,
      easing: "ease"
    },
    hide: {
      preset: "fade",
      speed: 300,
      easing: "ease",
      offset: 50
    },
    shift: {
      speed: 300,
      easing: "ease"
    }
  }
};

export const projectTunes = {
  Bloom: {
    name: "bloom.mp3",
    value: "Tone 1"
  },
  Calypso: {
    name: "calypso.mp3",
    value: "Tone 2"
  },
  Canfare: {
    name: "canfare.mp3",
    value: "Tone 3"
  },
  "New Mail": {
    name: "new_mail.mp3",
    value: "Tone 4"
  },
  "Payment Success": {
    name: "payment_success.mp3",
    value: "Tone 5"
  },
  "Sms Received": {
    name: "sms_received.mp3",
    value: "Tone 6"
  }
};

export const accessLimitations: Boolean = true;
export const isProduction: Boolean = true;
