import { Injectable } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import { SharedService } from "../shared/shared.service";

@Injectable({
  providedIn: "root"
})
export class WorkspaceGuard implements CanActivate {
  constructor(private authService: SharedService, private router: Router) {}
  canActivate() {
    if (this.authService.emailVerification()) {
      return true;
    } else {
      this.router.navigate(["index"]);
    }
  }
}
