import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { take } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";
import { ProfileService } from "../firestore/firestore.service";
import { NotifierService } from "angular-notifier";
import { DataService } from "../data/data.service";

@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  userID: String;
  notifier: NotifierService;
  audio = new Audio();

  constructor(
    private profileService: ProfileService,
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging,
    private notifierService: NotifierService,
    private dataService: DataService
  ) {
    this.angularFireMessaging.messaging.subscribe(_messaging => {
      _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    });
    this.notifier = this.notifierService;
    this.audio.src = "../../../assets/sounds/notification.mp3";
    this.audio.load();
  }

  /**
   * update token in firebase database
   *
   * @param userId userId as a key
   * @param token token as a value
   */
  updateToken(userId, token) {
    // we can change this function to request our backend service
    this.angularFireAuth.authState.pipe(take(1)).subscribe(() => {
      const data = {};
      data[userId] = token;
      this.profileService.updateProfile(userId, { deviceToken: token }, false);
      // this.angularFireDB.object("fcmTokens/").update(data);
    });
  }

  /**
   * request permission for notification from firebase cloud messaging
   *
   * @param userId userId
   */
  requestPermission(userId) {
    this.angularFireMessaging.requestToken.subscribe(
      token => {
        this.updateToken(userId, token);
      },
      err => {
        console.error("Unable to get permission to notify.", err);
      }
    );
  }

  /**
   * request permission for notification from firebase cloud messaging
   *
   * @param token token
   */
  // deleteToken(token) {
  //   this.angularFireMessaging.deleteToken(token).subscribe;
  // }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(payload => {
      const notification = <any>payload;
      if (notification.data.payLoad) {
        const { Type, workspaceID } = JSON.parse(notification.data.payLoad);
        if (this.dataService.workspaceID === workspaceID) {
          if (Type === "meeting") {
            // Do nothing
          } else {
            this.audio.play();
            this.notifier.notify("info", `${notification.data.body}`);
            this.currentMessage.next(payload);
          }
        }
      }
    });
  }
}
