import { NgModule } from "@angular/core";
import {
  Routes,
  RouterModule,
  PreloadAllModules,
  Route
} from "@angular/router";
import { ProjectDetailsService } from "./pages/project/project-details.service";
import { NotFoundComponent } from "./pages/not-found/not-found.component";

const fallback: Route = {
  path: "**",
  component: NotFoundComponent
};

const routes: Routes = [
  {
    path: "",
    loadChildren: "./features/intro/intro.module#IntroModule"
  },
  {
    path: "workspace",
    loadChildren: "./features/dashboard/dashboard.module#DashboardModule"
  },
  fallback
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: "reload",
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule],
  providers: [ProjectDetailsService]
})
export class AppRoutingModule {}
