import { Injectable } from "@angular/core";
import { Resolve, Router } from "@angular/router";
import { DataService } from "../data/data.service";

@Injectable({
  providedIn: "root"
})
export class PaymentResolver implements Resolve<any> {
  constructor() {}

  resolve(): Promise<any> {
    return new Promise(async resolve => {});
  }
}

@Injectable({
  providedIn: "root"
})
export class CheckoutResolver implements Resolve<any> {
  constructor(private dataService: DataService, private router: Router) {}

  resolve(): Promise<any> {
    return new Promise(resolve => {
      if (this.dataService.selectedPlan) {
        resolve();
      } else {
        resolve();
        this.router.navigate(["workspace/payment"]);
      }
    });
  }
}
