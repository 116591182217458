import { Injectable } from "@angular/core";
import { References } from "../firebase/firebase.service";

@Injectable()
export class UserService {
  constructor(private ref: References) {}

  async getCurrentUser() {
    return await new Promise<any>((resolve, reject) => {
      var user = this.ref.auth.onAuthStateChanged(function(user) {
        if (user) {
          resolve(user);
        } else {
          reject("No user logged in");
        }
      });
    });
  }

  updateUser(email) {
    return new Promise<any>((resolve, reject) => {
      var user = this.ref.auth.currentUser;
      user.updateEmail(email).then(
        res => {
          resolve(res);
        },
        err => reject(err)
      );
    });
  }
}
