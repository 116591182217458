import { Injectable } from "@angular/core";
import { Resolve, Router, ActivatedRouteSnapshot } from "@angular/router";
import * as CryptoJS from "crypto-js";
import { getSubdomain } from "../../utils/index";
import { UserService } from "../auth/user.service";
import { DataService } from "../data/data.service";

@Injectable({
  providedIn: "root"
})
export class DeeplinkResolverService implements Resolve<any> {
  ciphertext: String;

  type: String;
  docID: String;
  workspaceID: String;
  userID: String;
  projectID: String;
  projectTag: String;
  taskID: String;
  taskTag: String;

  loggedInUser: String;

  constructor(private router: Router, private dataService: DataService) {}

  resolve(route: ActivatedRouteSnapshot): Promise<any> {
    return new Promise(async resolve => {
      this.ciphertext = route.queryParams["encrypt"];
      var replaced = this.ciphertext.split(" ").join("+");

      let queryParams = this.decrypt(replaced);

      this.type = queryParams["Type"];
      this.docID = queryParams["ID"];
      this.workspaceID = queryParams["workspaceID"];
      this.userID = queryParams["userID"];
      this.projectID = queryParams["projectID"];
      this.taskID = queryParams["taskID"];

      // this.userService.getCurrentUser().then(
      //   user => {
      // if(this.dataService.userID) {

      if (this.type === "project") {
        this.projectTag = queryParams["tag"];
        // localStorage.setItem("projectID", this.docID.toString());
        this.dataService.projectID = this.docID.toString();
        this.router.navigate(["workspace", "p", this.projectTag]);
      } else if (this.type === "task") {
        this.projectTag = queryParams["taskTag"].split("-")[0];
        this.taskTag = queryParams["taskTag"];
        // localStorage.setItem("projectID", this.projectID.toString());
        // localStorage.setItem("taskID", this.docID.toString());
        this.dataService.projectID = this.projectID.toString();
        this.dataService.taskID = this.docID.toString();
        this.router.navigate([
          "workspace",
          "p",
          this.projectTag,
          "t",
          this.taskTag
        ]);
      } else if (this.type === "discussion") {
        this.projectTag = queryParams["taskTag"].split("-")[0];
        this.taskTag = queryParams["taskTag"];
        // localStorage.setItem("projectID", this.projectID.toString());
        // localStorage.setItem("taskID", this.taskID.toString());
        this.dataService.projectID = this.projectID.toString();
        this.dataService.taskID = this.taskID.toString();
        this.router.navigate(
          ["workspace", "p", this.projectTag, "t", this.taskTag],
          { fragment: `d${this.docID}` }
        );
      } else {
        this.router.navigate(["workspace", "projects"]);
      }
      return resolve(false);
      // }
      // },
      // err => {
      // else {

      localStorage.setItem("redirect", window.location.href);
      this.router.navigate([getSubdomain(), "signin"]);
      return resolve(true);
      // }
      // }
      // );
    });
  }

  decrypt(ciphertext) {
    // Decrypt
    var bytes = CryptoJS.AES.decrypt(ciphertext.toString(), "secret key 123");
    var plaintext = bytes.toString(CryptoJS.enc.Utf8);
    let queryParams = JSON.parse(
      '{"' +
        decodeURI(plaintext.replace(/&/g, '","').replace(/=/g, '":"')) +
        '"}'
    );
    return queryParams;
  }
}
