import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { isSubdomain } from "src/app/utils";

@Injectable()
export class SubdomainGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(): Promise<boolean> {
    return new Promise(resolve => {
      if (isSubdomain()) {
        return resolve(true);
      } else {
        this.router.navigate([""]);
        return resolve(false);
      }
    });
  }
}
