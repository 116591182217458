import { BrowserModule } from "@angular/platform-browser";
import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { environment } from "../environments/environment";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { enableProdMode } from '@angular/core';

// External Modules
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireMessagingModule } from "@angular/fire/messaging";
import 'hammerjs';
import { CookieService } from "ngx-cookie-service";
import { MatSnackBarModule } from "@angular/material";
import { NotifierModule } from "angular-notifier";
import { DeviceDetectorModule } from "ngx-device-detector";

// Pages //
import { AppComponent } from './app.component';
import { NotFoundComponent } from "./pages/not-found/not-found.component";

// Services //
import { SharedService } from "./services/shared/shared.service";
import { ProjectDetailsService } from "./pages/project/project-details.service";
import { AuthService } from "./services/auth/auth.service";
import { DeeplinkResolverService } from "./services/deeplink/deeplink-resolver.service";
import {
  FirestoreService,
  TaskService,
  DiscussionService,
  ProfileService,
  ProjectService,
  SearchingService,
  PaymentService,
  IdService
} from "./services/firestore/firestore.service";
import { UploadService } from "./services/upload/upload.service";
import { UserService } from "./services/auth/user.service";
import { AuthGuard } from "./services/auth/auth.guard";
import { SecurityGuard } from "./services/auth/security.guard";
import { WorkspaceGuard } from "./services/auth/workspace.guard";
import { References, Queries } from "./services/firebase/firebase.service";
import { MessagingService } from "./services/messaging/messaging.service";
import { CheckoutResolver } from "./services/resolvers/resolvers.service";
import { ProjectGuard, TaskGuard, ProfileGuard } from "./services/guards";
import { SubdomainGuard } from "./services/auth/subdomain.guard";

import { notifierConfig } from "../app/constants/index";
// import { PrivacypolicyComponent } from './pages/privacypolicy/privacypolicy.component';

enableProdMode();

@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSnackBarModule,
    NotifierModule.withConfig(notifierConfig),
    DeviceDetectorModule.forRoot()
  ],
  providers: [
    SharedService,
    ProjectDetailsService,
    DeeplinkResolverService,
    FirestoreService,
    UploadService,
    AuthService,
    AuthGuard,
    UserService,
    SecurityGuard,
    WorkspaceGuard,
    References,
    TaskService,
    DiscussionService,
    ProfileService,
    ProjectService,
    SearchingService,
    Queries,
    MessagingService,
    CheckoutResolver,
    CookieService,
    PaymentService,
    IdService,
    ProjectGuard,
    TaskGuard,
    SubdomainGuard,
    ProfileGuard,
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule {}
