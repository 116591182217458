import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { AngularFirestoreCollection } from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { References } from "src/app/services/firebase/firebase.service";
import { Project } from "../../models/interfaces";
import { DataService } from "src/app/services/data/data.service";

@Injectable({
  providedIn: "root"
})
export class ProjectDetailsService implements Resolve<any> {
  projectsCollection: AngularFirestoreCollection<Project>;
  projects: Observable<Project[]>;

  constructor(private ref: References, private dataService: DataService) {}

  //Working
  resolve(): Promise<any> {
    let workspaceID = this.dataService.workspaceID;
    return new Promise((resolve, reject) => {
      this.ref.projects
        .where("workspaceID", "==", workspaceID)
        .get()
        .then(querySnapshot => {
          let projects = [];
          querySnapshot.forEach(doc => {
            let data = doc.data();
            projects.push({
              id: doc.id,
              name: data.name,
              tasks: data.taskKey,
              members: data.members,
              colorCode: data.colorCode
            });
          });
          resolve(projects);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}
