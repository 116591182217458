import { fileTypes, regex } from "../constants/index";
import { map, without, orderBy } from "lodash";

export function generateRandomArray() {
  return Array.from({ length: 10 }, () => Math.floor(Math.random() * 10));
}

export function getProjectTag(name, tags) {
  let tag;
  const regex = /\s+/gi;
  const removeSpecialChar = name.trim().replace(/[^a-zA-Z ]/g, "");
  const wordCount = removeSpecialChar
    .trim()
    .replace(regex, " ")
    .split(" ").length;

  if (wordCount === 1) {
    tag =
      removeSpecialChar.substr(0, 2);
      // removeSpecialChar.substr(removeSpecialChar.trimRight().length - 2, 1);
  } else {
    tag = removeSpecialChar.match(/\b(\w)/g).join("");
    
  }
  tag = tag.toUpperCase();
  while (tags.includes(tag)) {
    tag = tag + tag.substr(tag.length - 1, 1);
    // tag = tag + tag.substr(tag.length - 1, 1);
    // var tagdwords = tag.substring(wordCount, 0);
    // var tagdup = tag.substring(wordCount, tag.length).length;
    // return tagdwords+tagdup;
  }
  return tag.trim();
}

export function getUserTag(name: String) {
  let tag = "";
  let regex = /\s+/gi;
  let wordCount = name
    .trim()
    .replace(regex, " ")
    .split(" ").length;
  if (wordCount === 1) {
    tag = (name.substr(0, 1) + name.substr(1, 1)).toUpperCase();
  } else {
    tag = name
      .match(/\b(\w)/g)
      .join("")
      .toUpperCase();
  }
  return tag.slice(0, 2);
}

export function getSubdomain() {
  const domain = window.location.hostname;
  let subdomain = domain.split('.')[0];

  if (subdomain === 'localhost' || subdomain === 'peprix') {

    subdomain = localStorage.getItem('workspaceName');

  }

  if(subdomain == null){

    return 'localhost';

  }

  return subdomain;
}

export function isSubdomain() {
  const domain = window.location.hostname;
  if (
    domain.indexOf(".") < 0 ||
    domain.split(".")[0] === "peprix" ||
    domain.split(".")[0] === "localhost" ||
    domain.split(".")[0] === "www"
  ) {
    return false;
  } else {
    return true;
  }
}

export function formatBytes(bytes, decimals) {
  if (bytes == 0) return "0 Bytes";
  var k = 1024,
    dm = decimals <= 0 ? 0 : decimals || 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function textMarkups(text: String) {
  let modifiedText = text
    .replace(regex.boldExp, "<strong>$1</strong>")
    .replace(regex.italicExp, "<i>$1</i>")
    .replace(regex.bulletedExp, "&#8226; $1")
    .replace(regex.urlExp, "<a href='$1' target='_blank'>$1</a>")
    .replace(regex.emailExp, '<a href="mailto:$1">$1</a>');
  return modifiedText;
}

export function getFileType(name) {
  let ext = "";
  let type = "";

  let i = name.lastIndexOf(".");
  if (i !== -1) {
    ext = name.slice(i + 1, name.length).toLowerCase();
  } else {
    return "unknown";
  }

  if (fileTypes.audio.includes(ext)) {
    type = "audio";
  } else if (fileTypes.video.includes(ext)) {
    type = "video";
  } else if (fileTypes.doc.includes(ext)) {
    type = "doc";
  } else if (fileTypes.image.includes(ext)) {
    type = "image";
  } else if (fileTypes.compress.includes(ext)) {
    type = "compress";
  } else if (fileTypes.ppt.includes(ext)) {
    type = "ppt";
  } else if (fileTypes.excel.includes(ext)) {
    type = "excel";
  } else if (fileTypes.technical.includes(ext)) {
    type = "technical";
  } else if (ext === "pdf") {
    type = "pdf";
  } else if (ext === "txt") {
    type = "txt";
  } else if (ext === "gif") {
    type = "gif";
  } //else if (ext === "exe") {
    //type = "exe";
  //} 
  else {
    type = "unknown";
  }
  return type;
}

export function accountHelper(current, remains, selected) {
  let availableMembers =
    +selected.availableMembers -
    current.availableMembers +
    +remains.availableMembers;

  let availableProjects =
    +selected.availableProjects -
    current.availableProjects +
    +remains.availableProjects;

  let availableStorage =
    +selected.availableStorage -
    current.availableStorage +
    +remains.availableStorage;

  return { availableMembers, availableProjects, availableStorage };
}

export function projectsHelper(data, userID, members) {
  let projectName = data.name;
  let projectTag = data.tag;
  let colorCode = data.colorCode;
  let tasksMap = data.tasks;
  let noTasks = data.taskKey;
  let member = data.members;
  let admins = data.admins;
  let createdBy = data.createdBy;
  let taskTag = `${projectTag}-${+noTasks}`;

  let projectMembers = [];
  let projectMembersIDs = [];
  let isAdmin;

  if (admins) {
    isAdmin = admins.includes(userID);
    for (let i = 0; i < admins.length; i++) {
      for (let j = 0; j < members.length; j++) {
        if (admins[i] === members[j].memberId) {
          projectMembers.push({
            memberId: members[j].memberId,
            memberName: members[j].memberName,
            data: members[j].data,
            isAdmin: true
          });
        }
      }
    }
  } else {
    return false;
  }
}

export function validateFileType(type) {
  if (
    type === "audio" ||
    type === "video" ||
    type === "doc" ||
    type === "image" ||
    type === "compress" ||
    type === "ppt" ||
    type === "excel" ||
    type === "pdf" ||
    type === "txt" ||
    type === "technical"||
    type === "gif"
    //type === "exe"
  ) {
    return true;
  } else {
    return false;
  }
}

export function validateFileSize(file, type) {
  let size = file.size;
  switch (type) {
    case "image": {
      if (size > 10000000) {
        return "Image size is exceeding 10 MB limit";
      } else {
        return true;
      }
    }

    case "audio": {
      if (size > 200000000) {
        return "Audio size is exceeding 200 MB limit";
      } else {
        return true;
      }
    }

    case "video": {
      if (size > 300000000) {
        return "Video size is exceeding 300 MB limit";
      } else {
        return true;
      }
    }

    case "compress": {
      if (size > 200000000) {
        return "Archive size is exceeding 200 MB limit";
      } else {
        return true;
      }
    }

    // case "exe": {
    //   if (size > 1000000000) {
    //     return "exe file size is exceeding 1 GB limit";
    //   } else {
    //     return true;
    //   }
    // }

    case "doc":
    case "ppt":
    case "pdf":
    case "txt":
    case "excel":
    case "gif":
    case "technical": {
      if (size > 200000000) {
        return "File size is exceeding 200 MB limit";
      } else {
        return true;
      }
    }

    default: {
      return "File type is not supported";
    }
  }
}

export function filter(array, key, value) {
  let filteredData = map(array, function(o) {
    if (o[key] === value) return o;
  });
  return without(filteredData, undefined);
}

export function filterChild(array, key1, key2, value) {
  let filteredData = map(array, function(o) {
    if (o[key1][key2] === value) return o;
  });
  return without(filteredData, undefined);
}

export function sort(array, key, order) {
  let orderedArray = orderBy(array, [`${key}`], [`${order}`]);
  return orderedArray;
}

export function copyToClipboard(fragment: String) {
  var url = window.location.href.split("#")[0];
  var element = document.createElement("input");
  document.body.appendChild(element);
  if (fragment) {
    url = url.concat(`#d${fragment.toString()}`);
  }
  element.value = url;
  element.select();
  document.execCommand("copy");
  document.body.removeChild(element);
}
