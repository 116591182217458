import { isEmpty } from "lodash";
import { textMarkups, generateRandomArray } from "./index";

export function projectsHelper(data, userID, members) {
  const projectName = data.name;
  const projectTag = data.tag;
  const colorCode = data.colorCode;
  const tuneName = data.tuneName;
  const tasksMap = data.tasks;
  const noTasks = data.taskKey;
  const member = data.members;
  let admins = data.admins;
  const createdBy = data.createdBy;
  const taskTag = `${projectTag}-${+noTasks}`;

  let projectMembers = [];
  let projectMembersIDs = [];
  let isAdmin;

  if (admins) {
    isAdmin = admins.includes(userID);
    for (let i = 0; i < admins.length; i++) {
      for (let j = 0; j < members.length; j++) {
        if (admins[i] === members[j].memberId) {
          projectMembers.push({
            memberId: members[j].memberId,
            memberName: members[j].memberName,
            data: members[j].data,
            isAdmin: true
          });
        }
      }
    }
  } else {
    admins = [];
  }

  if (member) {
    for (var b in Object.keys(member)) {
      for (let i = 0; i < members.length; i++) {
        if (Object.keys(member)[b] === members[i].memberId) {
          if (!admins.includes(Object.keys(member)[b])) {
            projectMembers.push({
              memberId: members[i].memberId,
              memberName: members[i].memberName,
              data: members[i].data,
              isAdmin: false
            });
          }
          projectMembersIDs.push(members[i].memberId);
        }
      }
    }
  }
  let noMembers = projectMembers.length - 5;

  let membersObj = {};
  for (let i = 0; i < members.length; i++) {
    if (members.memberId !== createdBy) {
      if (projectMembersIDs.includes(members[i].memberId)) {
        membersObj["member" + i] = ["true"];
      } else {
        membersObj["member" + i] = [""];
      }
    }
  }

  return {
    projectName,
    projectTag,
    colorCode,
    tuneName,
    tasksMap,
    noTasks,
    taskTag,
    createdBy,
    isAdmin,
    projectMembers,
    projectMembersIDs,
    noMembers,
    membersObj
  };
}

export function discussionHelper(data, userID, members) {
  let messageToDisplay = "";
  if (data.message && data.message != "") {
    messageToDisplay = data.message;
    messageToDisplay = textMarkups(messageToDisplay);
  }
  let mentionUsers = [];
  let time;
  if (data.createdAt) {
    time = +data.createdAt.seconds * 1000;
  }
  let isEditable = false;
  let isEdited = false;
  let hasAttachments = isEmpty(data.Attachments);
  if (data.from === userID) {
    isEditable = true;
  } else {
    isEditable = false;
  }
  if (data.updatedAt > data.createdAt) {
    isEdited = true;
  }
  let senderName, senderImage, senderTag;
  for (let id in Object.keys(data.mentionUsers)) {
    mentionUsers.push(Object.keys(data.mentionUsers)[id]);
  }
  for (let i = 0; i < members.length; i++) {
    if (members[i].memberId === data.from) {
      senderName = members[i].data.name;
      senderTag = members[i].memberName;
      senderImage = members[i].data.imageUrl;
    }

    if (mentionUsers.includes(members[i].memberId)) {
      let find = `@${members[i].data.name}`;
      let regex = new RegExp(find, "g");
      messageToDisplay = messageToDisplay.replace(
        regex,
        `<span class="link">@${members[i].data.name}</span>`
      );
    }
  }
  return {
    senderName,
    senderTag,
    senderImage,
    messageToDisplay,
    time,
    isEdited,
    isEditable,
    hasAttachments
  };
}

export function notificationHelper(data, userID, members) {
  let isMyNotification = false;

  let originator = data.originator;
  let originatorImage = "";
  let originatorTag = "";
  for (let i = 0; i < members.length; i++) {
    if (members[i].memberId === originator) {
      originatorImage = members[i].data.imageUrl;
      originatorTag = members[i].memberName;
    }
  }

  let colorCode = "";
  let type = data.type;
  if (type === "task") {
    if (data.parent.type === 0) {
      colorCode = "#eb496f";
    } else if (data.parent.type === 1) {
      colorCode = "#82b848";
    } else {
      colorCode = "#eb9d49";
    }
  } else if (type === "project") {
    colorCode = "#0630b8";
  } else if (type === "discussion") {
    colorCode = "#0b56ef";
  } else if (type === " attachment") {
    colorCode = "#21c1ff";
  } else {
    //do nothing
  }

  let title = data.message.title;
  let name = data.message.name.toString();
  let tag = data.message.tag.toString();
  let titleWithoutNameAndTag = title.replace(name, "").replace(tag, "");

  let infoToRender = "";
  let moreInfo = data.message.moreInfo;
  infoToRender = moreInfo
    .replace("<blue>", '<span class = "link">')
    .replace("</blue>", "</span>");

  if (originator === userID) {
    isMyNotification = false;
  } else {
    isMyNotification = true;
  }

  return {
    isMyNotification,
    originatorImage,
    originatorTag,
    colorCode,
    name,
    tag,
    titleWithoutNameAndTag,
    infoToRender
  };
}

export function activitiesHelper(data) {
  let title = data.message.title;
  let name = data.message.name.toString();
  let tag = data.message.tag.toString();
  let titleWithoutName = title.replace(name, "");
  let titleWithoutTag = titleWithoutName.replace(tag, "");

  let infoToRender = "";

  let moreInfo = data.message.moreInfo;
  infoToRender = moreInfo
    .replace("<blue>", '<span class = "link">')
    .replace("</blue>", "</span>");

  return {
    name,
    tag,
    titleWithoutTag,
    infoToRender
  };
}

export function projectMembersHelper(data, members) {
  let admins = data.admins;
  let member = data.members;
  let projectMembers = [];
  let projectMembersIDs = [];

  if (admins) {
    for (let i = 0; i < admins.length; i++) {
      for (let j = 0; j < members.length; j++) {
        if (admins[i] === members[j].memberId) {
          projectMembers.push({
            memberId: members[j].memberId,
            memberName: members[j].memberName,
            data: members[j].data,
            isAdmin: true,
            chart: generateRandomArray(),
            creator: data.createdBy === members[j].memberId ? true : false
          });
        }
      }
    }
  } else {
    admins = [];
  }

  if (member) {
    for (var b in Object.keys(member)) {
      for (let i = 0; i < members.length; i++) {
        if (Object.keys(member)[b] === members[i].memberId) {
          if (!admins.includes(Object.keys(member)[b])) {
            projectMembers.push({
              memberId: members[i].memberId,
              memberName: members[i].memberName,
              data: members[i].data,
              isAdmin: false,
              chart: generateRandomArray(),
              creator: data.createdBy === members[i].memberId ? true : false
            });
          }
          projectMembersIDs.push(members[i].memberId);
        }
      }
    }
  }

  return {
    projectMembers,
    projectMembersIDs
  };
}
