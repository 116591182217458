import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AngularFirestore } from "@angular/fire/firestore";
import { References } from "../firebase/firebase.service";

@Injectable({
  providedIn: "root"
})
export class UploadService {
  filesHolder: File[] = [];

  constructor(private af: AngularFirestore, private ref: References) {}

  uploads: Observable<Upload[]>;

  pushUpload(upload: Upload, path, docID, payload) {
    this.saveFileData(docID, payload);
    let storageRef = this.ref.storage.ref();
    let uploadTask = storageRef
      .child(`${path}/${upload.file.name}`)
      .put(upload.file);

    uploadTask.on(
      this.ref.firebase.storage.TaskEvent.STATE_CHANGED,
      snapshot => {
        // upload in progress
        upload.progress = Math.round(
          (uploadTask.snapshot.bytesTransferred /
            uploadTask.snapshot.totalBytes) *
            100
        );
      },
      error => {
        console.error(error);
      },
      () => {
        // upload success
        upload.name = upload.file.name;
        uploadTask.snapshot.ref
          .getDownloadURL()
          .then(url => {
            upload.url = url;
          })
          .catch(error => {
            console.error(error);
          });
      }
    );
  }

  taskAttachmentsUpload(upload: Upload, path, docID, payload) {
    this.saveFileData(docID, payload);
    let storageRef = this.ref.storage.ref();
    storageRef
      .child(`${path}/${upload.file.name}`)
      .put(upload.file)
      .catch(error => {
        console.error(error);
      });
  }

  attachmentInDiscussionUpload(
    upload: Upload,
    path,
    discussionDocID,
    attachmentDocID,
    payload
  ) {
    // this.af.collection('Discussions').doc(discussionDocID).update(
    //   {
    //     Attachments: { [attachmentDocID]: payload }
    //   }
    // );

    let storageRef = this.ref.storage.ref();
    storageRef
      .child(`${path}/${upload.file.name}`)
      .put(upload.file)
      .catch(error => {
        console.error(error);
      });
  }

  profilePictureUpload(upload: ProfilePicture, path) {
    let storageRef = this.ref.storage.ref();
    storageRef
      .child(`${path}/${upload.file.name}`)
      .put(upload.file)
      .catch(error => {
        console.error(error);
      });
  }

  // Writes the file details to the firestore
  private saveFileData(docID, payload) {
    this.af
      .collection("Attachments")
      .doc(docID)
      .set(payload);
  }

  deleteUpload(fileURL, thumbURL, docID) {
    this.deleteFileData(docID)
      .then(() => {
        this.deleteFileStorage(fileURL, thumbURL);
      })
      .catch(error => console.error(error));
  }

  // Deletes the file details from the firestore collection
  private deleteFileData(id) {
    return this.af
      .collection("Attachments")
      .doc(id)
      .delete();
  }

  // Firebase files must have unique names in their respective storage dir
  // So the name serves as a unique key
  private deleteFileStorage(url, thumbURL) {
    this.ref.storage.refFromURL(url).delete();
    if (thumbURL) this.ref.storage.refFromURL(thumbURL).delete();
  }
}

export class Upload {
  $key: string;
  file: File;
  name: string;
  url: string;
  progress: number;
  createdAt: Date = new Date();

  constructor(file: File) {
    this.file = file;
  }
}

export class ProfilePicture {
  file: File;
  fileName: String;
  fileUrl: String;

  constructor(file: File) {
    this.file = file;
  }
}
