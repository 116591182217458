import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { DataService } from "../data/data.service";
import { IdService } from "../firestore/firestore.service";

@Injectable()
export class ProjectGuard implements Resolve<any> {
  projectTag: String;
  constructor(
    private router: Router,
    private dataService: DataService,
    private idService: IdService
  ) {  }

  resolve(route: ActivatedRouteSnapshot): Promise<boolean> {
    this.projectTag = route.params["projectTag"];
    return new Promise((resolve, reject) => {
      if (this.dataService.projectID) {
        resolve(true);
      } else {
        this.idService
          .getProjectId(
            this.dataService.workspaceID,
            this.dataService.userID,
            this.projectTag
          )
          .then(snapshot => {
            if (snapshot.docs.length > 0) {
              this.dataService.projectID = snapshot.docs[0].id;
              resolve(true);
            } else {
              this.router.navigate(["**"]);
              resolve(false);
            }
          })
          .catch(err => {
            console.error(err);
            reject(err);
          });
      }
    });
  }
}

@Injectable()
export class TaskGuard implements Resolve<any> {
  projectTag: String;
  taskTag: String;
  constructor(
    private router: Router,
    private dataService: DataService,
    private idService: IdService
  ) {  }

  resolve(route: ActivatedRouteSnapshot): Promise<boolean> {
    this.projectTag = route.params["projectTag"];
    this.taskTag = route.params["taskTag"];
    return new Promise((resolve, reject) => {
      if (this.dataService.taskID) {
        resolve(true);
      } else if (!this.taskTag.includes(this.projectTag.toString())) {
        this.router.navigate(["**"]);
        resolve(false);
      } else {
        this.idService
          .getTaskId(
            this.dataService.workspaceID,
            this.dataService.projectID,
            this.taskTag
          )
          .then(snapshot => {
            if (snapshot.docs.length > 0) {
              this.dataService.taskID = snapshot.docs[0].id;
              resolve(true);
            } else {
              this.router.navigate(["**"]);
              resolve(false);
            }
          })
          .catch(err => {
            console.error(err);
            reject(err);
          });
      }
    });
  }
}

@Injectable()
export class ProfileGuard implements Resolve<any> {
  userId: String;
  constructor(private router: Router, private dataService: DataService) {  }

  resolve(route: ActivatedRouteSnapshot): Promise<boolean> {
    this.userId = route.params["userId"];
    return new Promise(resolve => {
      const members = this.dataService.members;
      for (let i = 0; i < members.length; i++) {
        if (members[i].memberId === this.userId) {
          return resolve(members[i]);
        }
      }
      this.router.navigate(["**"]);
      resolve(false);
    });
  }
}
