import { Injectable } from "@angular/core";
import {
  FirestoreService,
  SearchingService
} from "../firestore/firestore.service";
import { Observable, Subscription } from "rxjs";
import { accessLimitations } from "src/app/constants";

@Injectable({
  providedIn: "root"
})
export class DataService {
  auth: Subscription;
  data: Boolean;
  loggedInUser: any;
  email: String;
  userID: String;
  workspaceID: String;
  projectID: String;
  taskID: String;
  isAdmin: Boolean;
  isCustomer: Boolean;

  selectedPlan: String;
  accountID: String;

  members$: Observable<any>;
  members: Array<any>;
  membersSubscription: Subscription;

  account: Object;
  accountsSubscription: Subscription;

  projects$: Observable<any>;
  projects: Object;
  myProjects: Array<String> = [];
  myProjectsSubscription: Subscription;

  projectMembers: Object;
  projectAdmin: Boolean;

  tasks$: Observable<any>;
  tasks: Object;

  paymentSuccess: Boolean = false;
  tasksScreenFragmentState: String;
  tasksScreenFragmentId: String;

  constructor(
    private firestoreService: FirestoreService,
    private searchingService: SearchingService
  ) {}

  async refresher(resolve) {
    await this.user();

    this.members$ = this.firestoreService.getWorkspaceMembers(this.workspaceID);

    this.membersSubscription = this.members$.subscribe(items => {
      this.members = items;
      this.firestoreService.members = items;
      this.searchingService.members = items;
      resolve();
    });

    if (accessLimitations) {
      this.accountsSubscription = this.firestoreService
        .getAccount(this.workspaceID)
        .subscribe(accounts => {
          this.account = accounts[0];
        });
    }
  }

  user(): Promise<String> {
    return new Promise(resolve => {
      this.firestoreService.getUser(this.userID, 1).subscribe(data => {
        this.workspaceID = data.workspaceID;
        this.email = data.email;
        this.isAdmin = data.isAdmin;
        this.isCustomer = data.isCustomer;
        resolve();
      });
    });
  }
}
