import { Component, OnInit } from "@angular/core";
import { Router, Event, ResolveStart, ResolveEnd } from "@angular/router";
import { fadeAnimation } from "./animations";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  animations: [fadeAnimation]
})
export class AppComponent {
  title = "squad";
  loading: boolean = true;

  constructor(private router: Router) {
    router.events.subscribe((routerEvent: Event) => {
      this.checkRouterEvent(routerEvent);
    });
  }

  checkRouterEvent(routerEvent: Event): void {
    if (routerEvent instanceof ResolveStart) {
      this.loading = true;
    }

    if (routerEvent instanceof ResolveEnd) {
      this.loading = false;
    }
  }

}
