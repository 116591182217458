import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot } from "@angular/router";
import { UserService } from "./user.service";
import { SharedService } from "../shared/shared.service";
import { DataService } from "../data/data.service";
import { isSubdomain, getSubdomain } from "src/app/utils";

@Injectable({
  providedIn: "root"
})
export class SecurityGuard implements CanActivate {
  userID: String;
  workspaceID: String;
  constructor(
    private userService: UserService,
    private router: Router,
    private authService: SharedService,
    private dataService: DataService
  ) {}
  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      this.userService.getCurrentUser().then(
        user => {
          this.dataService.userID = user.uid;
          if (route.queryParams["workspaceID"]) {
            this.workspaceID = route.queryParams["workspaceID"];
            this.authService.isMember(this.userID).then(doc => {
              if (doc["workspaceID"] !== this.workspaceID) {
                this.router.navigate(["not-found"]);
                reject(false);
              } else {
                resolve(true);
                return true;
              }
            });
          } else {
            resolve(true);
            return true;
          }
        },
        err => {
          if (isSubdomain) {
            localStorage.setItem(
              "redirect",
              `${window.location.pathname}${window.location.hash}`
            );
            this.router.navigate([getSubdomain(), "signin"]);
          } else {
            this.router.navigate(["signin"]);
          }
          reject(false);
        }
      );
    });
  }
}
