import {
  trigger,
  animate,
  style,
  query,
  stagger,
  transition,
  state
} from "@angular/animations";

export const fadeAnimation = trigger("fadeAnimation", [
  transition("* => *", [
    query(":enter", [style({ position: "absolute", opacity: 0 })], {
      optional: true
    }),
    query(
      ":leave",
      [
        style({ position: "absolute", width: "100%", opacity: 1 }),
        animate("0.2s", style({ opacity: 0 }))
      ],
      { optional: true }
    ),
    query(
      ":enter",
      [
        style({ width: "100%", opacity: 0 }),
        animate("0.2s", style({ opacity: 1 }))
      ],
      { optional: true }
    )
  ])
]);

export const listStagger = trigger("listStagger", [
  transition("* <=> *", [
    query(
      ":enter",
      [
        style({ opacity: 0, transform: "translateY(-10px)" }),
        stagger(
          "50ms",
          animate(
            "550ms ease-out",
            style({ opacity: 1, transform: "translateY(0px)" })
          )
        )
      ],
      { optional: true }
    ),
    query(":leave", animate("50ms", style({ opacity: 0 })), {
      optional: true
    })
  ])
]);

export const SlideInOutAnimation = [
  trigger("slideInOut", [
    state(
      "in",
      style({
        overflow: "hidden",
        height: "*"
      })
    ),
    state(
      "out",
      style({
        opacity: "0",
        overflow: "hidden",
        height: "0px"
      })
    ),
    transition("in => out", animate("400ms ease-in-out")),
    transition("out => in", animate("400ms ease-in-out"))
  ])
];

export const SlideInOutAnimationWithoutOpacity = [
  trigger("slideInOutWithoutOpacity", [
    state(
      "in",
      style({
        overflow: "hidden",
        height: "*",
        width: "350px"
      })
    ),
    state(
      "out",
      style({
        opacity: "0",
        overflow: "hidden",
        height: "0px",
        width: "0px"
      })
    ),
    transition("in => out", animate("400ms ease-in-out")),
    transition("out => in", animate("400ms ease-in-out"))
  ])
];

export const ScrollAnimation = [
  trigger("scrollAnimation", [
    state(
      "show",
      style({
        opacity: 1,
        transform: "translateX(0)"
      })
    ),
    state(
      "hide",
      style({
        opacity: 0,
        transform: "translateX(-100%)"
      })
    ),
    transition("show => hide", animate("1000ms ease-out")),
    transition("hide => show", animate("1000ms ease-in"))
  ])
];

export const BaloonEffect = [
  trigger("balloonEffect", [
    state(
      "initial",
      style({
        backgroundColor: "green",
        transform: "scale(1)"
      })
    ),
    state(
      "final",
      style({
        backgroundColor: "red",
        transform: "scale(1.5)"
      })
    ),
    transition("final=>initial", animate("1000ms")),
    transition("initial=>final", animate("1500ms"))
  ])
];

export const SpinInOut = [
  trigger("spinInOut", [
    state("in", style({ transform: "rotate(0)", opacity: "1" })),
    transition(":enter", [
      style({ transform: "rotate(-180deg)", opacity: "0" }),
      animate("150ms ease")
    ]),
    transition(":leave", [
      animate(
        "150ms ease",
        style({ transform: "rotate(180deg)", opacity: "0" })
      )
    ])
  ])
];

export const PreventInitialAnimation = [
  trigger("preventInitialAnimation", [
    transition(":enter", [query(":enter", [], { optional: true })])
  ])
];
