import { Injectable } from "@angular/core";
import { References } from "../firebase/firebase.service";
import { DataService } from "../data/data.service";

@Injectable({
  providedIn: "root"
})
export class SharedService {
  workspaceID: String;

  constructor(private ref: References, private dataService: DataService) {}

  isLoggedIn() {
    let userID = this.dataService.userID;
    if (userID) {
      return true;
    } else return false;
  }

  emailVerification() {
    let email = this.dataService.email;
    if (email) {
      return true;
    } else return false;
  }

  async hasJoined(invitationID) {
    let document = await new Promise((resolve, reject) => {
      this.ref.inviteMembers
        .doc(invitationID)
        .get()
        .then(doc => {
          resolve(doc.data());
        })
        .catch(error => {
          console.error(error);
          reject();
        });
    }).catch(error => {
      console.error(error);
    });
    return document;
  }

  async isMember(userID) {
    let document = await new Promise((resolve, reject) => {
      this.ref.users
        .doc(userID)
        .get()
        .then(doc => {
          resolve(doc.data());
        })
        .catch(error => {
          console.error(error);
          reject();
        });
    }).catch(error => {
      console.error(error);
    });
    return document;
  }

  async isProjectMember(projectID) {
    let document = await new Promise((resolve, reject) => {
      this.ref.projects
        .doc(projectID)
        .get()
        .then(doc => {
          resolve(doc.data());
        })
        .catch(error => {
          console.error(error);
          reject();
        });
    }).catch(error => {
      console.error(error);
    });
    return document;
  }
}
