import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

firebase.initializeApp(environment.firebaseConfig);
firebase.firestore().settings(environment.firestoreConfig);

@Injectable({
  providedIn: "root"
})
export class FirebaseService {
  constructor() {}
}

@Injectable()
export class References {
  firebase = firebase;
  firestore = firebase.firestore();
  auth = firebase.auth();
  workspaces = firebase.firestore().collection("Workspaces");
  accounts = firebase.firestore().collection("Accounts");
  users = firebase.firestore().collection("Users");
  projects = firebase.firestore().collection("Projects");
  tasks = firebase.firestore().collection("Tasks");
  attachments = firebase.firestore().collection("Attachments");
  discussions = firebase.firestore().collection("Discussions");
  inviteMembers = firebase.firestore().collection("InviteMembers");
  emailVerification = firebase.firestore().collection("EmailVerification");
  getStarted = firebase.firestore().collection("GetStarted");
  currentTime = firebase.firestore.FieldValue.serverTimestamp();
  fieldValue = firebase.firestore.FieldValue;
  storage = firebase.storage();
}

@Injectable()
export class Queries {
  add(collection, payload) {
    return firebase
      .firestore()
      .collection(collection)
      .add(payload)
      .catch(error => {
        console.error(error);
      });
  }

  set(collection, docID, payload) {
    let docRef = firebase
      .firestore()
      .collection(collection)
      .doc(docID);
    return docRef.set(payload).catch(error => {
      console.error(error);
    });
  }

  delete(collection, docID) {
    return firebase
      .firestore()
      .collection(collection)
      .doc(docID)
      .delete()
      .catch(error => {
        console.error(error);
      });
  }

  update(collection, docID, payload) {
    return firebase
      .firestore()
      .collection(collection)
      .doc(docID)
      .update(payload)
      .catch(error => {
        console.error(error);
      });
  }
}
