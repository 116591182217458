// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyDHbmDi0efmFlVG17YnjVMgc0F7PSswM5s",
    authDomain: "peprix-2a59f.firebaseapp.com",
    databaseURL: "https://peprix-2a59f.firebaseio.com",
    projectId: "peprix-2a59f",
    storageBucket: "peprix-2a59f.appspot.com",
    messagingSenderId: "522044314413",
    appId: "1:522044314413:web:ae24672defaa71307fb9a4",
    timestampsInSnapshots: true
  },
  firestoreConfig: {
    timestampsInSnapshots: true
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
